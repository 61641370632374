import { Box, Typography, useTheme } from '@mui/material'
import React, { FC } from 'react'

interface HourMarkerProps {
  index: number
  startTime: Date
  timelineWidth: number
  hoursViewWidth: number
  timelineHours: number
}

export const MIN_HOUR_WIDTH = 60

export const TimelineHourMarker: FC<HourMarkerProps> = ({
  index,
  startTime,
  timelineWidth,
  hoursViewWidth,
  timelineHours,
}) => {
  const theme = useTheme()

  const getHourVisibility = (index: number, hourWidth: number) => {
    /** Returns if an hour marker should be hidden or visible based on a min-width & hour index */
    const hour = index + 1
    if (hour % 2 === 0 && hourWidth < MIN_HOUR_WIDTH) {
      return 'hidden'
    }
    if ((hour === 1 || index % 4 === 0) && hourWidth * 2 < MIN_HOUR_WIDTH) {
      return 'hidden'
    }
    return 'visible'
  }

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexShrink: '0',
        flexGrow: '0',
        width:
          timelineWidth > 0 ? `${hoursViewWidth / timelineHours}px` : '1px',
        height: '100%',
        borderLeft: `2px solid ${theme.palette.divider}`,
        marginTop: '1.5em',
        position: 'relative',
        overflow: 'visible',
        visibility: getHourVisibility(index, hoursViewWidth / timelineHours),
      }}
    >
      <Typography
        align={'center'}
        sx={{
          position: 'absolute',
          top: '-1.5em',
          left: `-${MIN_HOUR_WIDTH / 2}px`,
          width: `${MIN_HOUR_WIDTH}px`,
        }}
        variant="subtitle1"
        color={'gray'}
      >
        {new Date(
          startTime.valueOf() + (index + 1) * (1000 * 60 * 60),
        ).toLocaleTimeString([], {
          hour: 'numeric', // eslint-disable-line i18next/no-literal-string
          hour12: true,
        })}
      </Typography>
    </Box>
  )
}
