import { Box } from '@mui/material'
import { createSelector } from '@reduxjs/toolkit'
import { FC, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Dashboard, DashboardLink } from '../../components/Dashboard/Dashboard'
import {
  DEVICES_TABLE_STATE_NAME,
  DevicesList,
} from '../../components/DevicesList/DevicesList'
import {
  clientApi,
  DeviceStatusPaginatedListRead,
  DeviceStatusRead,
} from '../../store/clientApi'
import { selectRtkData } from '../../store/slices/tableSlice'
import { RootState } from '../../store/store'
import { DevicesData } from './DevicesData'
interface DevicesProps {
  primaryLinks: DashboardLink[]
  footerLinks: DashboardLink[]
}

const Devices: FC<DevicesProps> = ({ primaryLinks, footerLinks }) => {
  const [tab, setTab] = useState<false | string>('all')
  const changeTab = (newValue: string | false) => {
    setTab(newValue)
  }

  // get devices for tab
  const selectThingNamesFromDevices = useMemo(() => {
    const emptyArray: DeviceStatusRead[] = []
    return createSelector(
      [(res?: DeviceStatusPaginatedListRead) => res?.content ?? emptyArray],
      (content) =>
        content
          .filter((device) =>
            tab === 'attention' ? device.status?.state === 'error' : true,
          )
          .map((device) => device.thing_name),
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [tab])

  const deviceThingNames = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      DEVICES_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    return selectThingNamesFromDevices(query?.data)
  })

  const error = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      DEVICES_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    return query?.error
  })

  const isLoading = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      DEVICES_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    return (
      query?.isLoading && (query?.isUninitialized || query?.data === undefined)
    )
  })

  return (
    <>
      <DevicesData />
      <Dashboard primaryLinks={primaryLinks} footerLinks={footerLinks}>
        <Box component="div" sx={{ height: '100%' }}>
          <DevicesList
            tab={tab}
            setTab={changeTab}
            deviceThingNames={deviceThingNames}
            error={error}
            isLoading={isLoading}
          />
        </Box>
      </Dashboard>
    </>
  )
}

export { Devices }
