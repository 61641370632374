import React, { FC } from 'react'
import { DashboardLink } from '../../components/Dashboard/Dashboard'
import { Timeline } from './Timeline'
import { TimelineData } from './TimelineData'

interface TimelineProps {
  primaryLinks: DashboardLink[]
  footerLinks: DashboardLink[]
}

export const TimelineContainer: FC<TimelineProps> = ({
  primaryLinks,
  footerLinks,
}) => {
  return (
    <>
      <TimelineData />
      <Timeline primaryLinks={primaryLinks} footerLinks={footerLinks} />
    </>
  )
}
