import {
  Box,
  CircularProgress,
  LinearProgress,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC, UIEventHandler, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { EmptyState } from '../../components'
import { Button } from '../../components/Button/Button'
import { Dashboard, DashboardLink } from '../../components/Dashboard/Dashboard'
import { DeviceViewer } from '../../components/DeviceViewer/DeviceViewer'
import { RightDrawer } from '../../components/RightDrawer/RightDrawer'
import { UnderConstruction } from '../../components/UnderConstruction/UnderConstruction'
import { ensureError, localizeError } from '../../helpers'
import { clientApi } from '../../store/clientApi'

import { selectRtkData } from '../../store/slices/tableSlice'
import { RootState } from '../../store/store'
import { TimelineHeader } from './TimelineHeader/TimelineHeader'
import {
  MIN_HOUR_WIDTH,
  TimelineHourMarker,
} from './TimelineHourMarker/TimelineHourMarker'
import { TimelinePrinters } from './TimelinePrinters/TimelinePrinters'
import { TimelineRows } from './TimelineRows/TimelineRows'

interface TimelineProps {
  primaryLinks: DashboardLink[]
  footerLinks: DashboardLink[]
}

const enrolDeviceLink = `https://${import.meta.env.VITE_AUTH0_DOMAIN}/activate`
const PRINTER_PANEL_WIDTH = 300
const PRINTER_PANEL_PADDING = 16

const calcHoursViewWidth = (width: number, timelineHours: number) => {
  const padding = 24
  const screenWidth =
    width - PRINTER_PANEL_WIDTH - PRINTER_PANEL_PADDING * 3 - padding
  const minWidth = MIN_HOUR_WIDTH * timelineHours - padding

  return Math.max(screenWidth, minWidth)
}

const calcTimelineWidth = (width: number, timelineHours: number) => {
  const screenWidth = width - PRINTER_PANEL_WIDTH - PRINTER_PANEL_PADDING * 3
  const minWidth = MIN_HOUR_WIDTH * timelineHours
  return Math.max(screenWidth, minWidth)
}

const Timeline: FC<TimelineProps> = ({ primaryLinks, footerLinks }) => {
  // hooks
  const { t } = useTranslation('timeline')
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const { allowDevicesUi } = useFlags()

  // states
  const [timelineHours, setTimelineHours] = useState<number>(12)
  const [timelineWidth, setTimelineWidth] = useState(
    MIN_HOUR_WIDTH * timelineHours - PRINTER_PANEL_WIDTH,
  )
  const [timelineHeight, setTimelineHeight] = useState(0)
  const [hoursViewWidth, setHoursViewWidth] = useState(
    MIN_HOUR_WIDTH * timelineHours,
  )
  const [tab, setTab] = useState<false | string>('all')
  const [featureFlagTimeout, setFeatureFlagTimeout] = useState<boolean>(false)

  const handleScrollTimeline: UIEventHandler<HTMLDivElement> = (e) => {
    // set scrollTop to the current scroll position of the container.
    const printers = document.getElementById('timeline-printers')
    if (e?.currentTarget && printers)
      printers.style.transform = `translateY(-${e?.currentTarget?.scrollTop}px)`
  }

  // calculate timeline start time (2hrs 20 before)
  const currentTime = new Date(Date.now()).setSeconds(0, 0) // update once per minute
  const flooredHourTime = new Date(currentTime - 1000 * 7200).setMinutes(0, 0, 0) // prettier-ignore
  const startTime = new Date(flooredHourTime - 1000 * 60 * 20) // Subtract 20 minutes (padding-left)

  const printersRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      setTimelineHeight(node.clientHeight)
    }
  }, [])
  const timelineRef = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        setTimelineWidth(calcTimelineWidth(node.clientWidth, timelineHours))
        setHoursViewWidth(calcHoursViewWidth(node.clientWidth, timelineHours))
      }
    },
    [timelineHours],
  )

  const handleResize = (node: HTMLDivElement | null) => {
    if (node) {
      setTimelineWidth(calcTimelineWidth(node.clientWidth, timelineHours))
      setHoursViewWidth(calcHoursViewWidth(node.clientWidth, timelineHours))
    }
  }

  // Feature Flag timeout as both error and loading are treated as undefined
  useEffect(() => {
    let timeoutId: any = null
    // Start timeout if allowDevicesUi is undefined
    if (!allowDevicesUi) {
      timeoutId = setTimeout(() => {
        setFeatureFlagTimeout(true)
      }, 3000) // 3 seconds
    }

    // Clear timeout on component unmount or if allowDevicesUi becomes defined
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [allowDevicesUi])

  const hasDevices = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      TIMELINE_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    return (query?.data?.content?.length ?? 0) > 0
  })

  const isLoading = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      TIMELINE_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    return (
      query?.isLoading && (query?.isUninitialized || query?.data === undefined)
    )
  })

  const error = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      TIMELINE_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    return query?.error
  })

  // Timeline page width excluding left navbar
  const TimelineMaxWidths = (theme: Theme) => ({
    [theme.breakpoints.up('xs')]: {
      maxWidth: 'calc(100vw)',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 'calc(100vw - 80px)',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 'calc(100vw - 100px)',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 'calc(100vw - 120px)',
    },
  })

  return (
    <Dashboard primaryLinks={primaryLinks} footerLinks={footerLinks}>
      {allowDevicesUi ? (
        <Box
          component="div"
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            maxHeight: '100%',
            boxSizing: 'border-box',
            maxWidth: '100%',
            overflow: 'hidden',
            gap: `${PRINTER_PANEL_PADDING}px`,
          }}
        >
          <Box
            component="div"
            ref={timelineRef}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'stretch',
              justifyContent: 'flex-start',
              width: '100%',
              height: '100%',
              maxHeight: '100%',
              backgroundColor: theme.surface.low,
              borderRadius: '1em',
              boxSizing: 'border-box',
              maxWidth: '100%',
              overflow: 'hidden',
            }}
            onResize={(e) => {
              handleResize(e.currentTarget)
            }}
          >
            <Box
              component="div"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'max-content',
                width: '100%',
                bgcolor: (theme: Theme) => theme.surface.low,
                padding: '1em 1em 0em 1em',
                boxSizing: 'border-box',
              }}
            >
              <TimelineHeader
                tab={tab}
                setTab={setTab}
                timelineHours={timelineHours}
                setTimelineHours={setTimelineHours}
              />
            </Box>
            {isLoading ? (
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  padding: '1em',
                  borderRadius: '1em',
                  overflow: 'auto',
                }}
              >
                <LinearProgress />
              </Box>
            ) : error ? (
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  padding: '1em',
                  borderRadius: '1em',
                  overflow: 'auto',
                }}
              >
                {localizeError(t, ensureError(error))}
              </Box>
            ) : hasDevices ? (
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignItems: 'stretch',
                  flexGrow: '1',
                  ...TimelineMaxWidths(theme),
                  height: '100%',
                  padding: `${PRINTER_PANEL_PADDING}px ${PRINTER_PANEL_PADDING}px 0px ${PRINTER_PANEL_PADDING}px`,
                  gap: `${PRINTER_PANEL_PADDING}px`,
                  boxSizing: 'border-box',
                  overflowY: 'hidden',
                  overflowX: 'hidden',
                  justifyContent: 'flex-end',
                }}
              >
                <Box
                  component="div"
                  sx={{
                    overflowX: 'auto',
                    overflowY: 'auto',
                    boxSizing: 'content-box',
                    height: '100%',
                    width: '100%',
                  }}
                  onScroll={handleScrollTimeline}
                >
                  <Box
                    component="div"
                    sx={{
                      width: `${hoursViewWidth}px`,
                      position: 'relative',
                      height: `${timelineHeight}px`,
                    }}
                  >
                    {/* Timeline */}
                    <Box
                      component="div"
                      sx={{
                        position: 'absolute',
                        width: `100%`,
                        height: `${timelineHeight}px`,
                      }}
                    >
                      {/* Hour Markers */}
                      <Box
                        component="div"
                        sx={{
                          display: 'flex',
                          flexGrow: '1',
                          height: `100%`,
                          minHeight: `100%`,
                          alignItems: 'stretch',
                          position: 'relative',
                          paddingTop: '1.5em',
                          marginTop: '-1.5em',
                        }}
                      >
                        {/* 20 minutes worth of additional left padding */}
                        <Box
                          component="div"
                          sx={{
                            height: '100%',
                            minWidth:
                              timelineWidth > 0
                                ? `${hoursViewWidth / timelineHours / 3}px`
                                : '1px',
                          }}
                        />
                        {Array.from(
                          {
                            length: timelineHours + 1,
                          },
                          (_, i) => (
                            <TimelineHourMarker
                              key={i}
                              index={i}
                              startTime={startTime}
                              timelineWidth={timelineWidth}
                              timelineHours={timelineHours}
                              hoursViewWidth={hoursViewWidth}
                            />
                          ),
                        )}
                      </Box>
                    </Box>
                    {/* Timeline Content */}
                    <Box
                      component="div"
                      sx={{
                        position: 'absolute',
                        width: `${hoursViewWidth + MIN_HOUR_WIDTH * 2}px`,
                        height: '100%',
                        overflow: 'hidden',
                        marginTop: '1.5em',
                      }}
                    >
                      <Box
                        component="div"
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          gap: '8px',
                          width: `${hoursViewWidth}px`,
                          paddingTop: `${PRINTER_PANEL_PADDING}px`,
                          boxSizing: 'border-box',
                        }}
                      >
                        {/* Full width row for each printer */}
                        {hasDevices && (
                          <TimelineRows
                            tab={tab}
                            hoursViewWidth={hoursViewWidth}
                            timelineHours={timelineHours}
                          />
                        )}
                      </Box>
                    </Box>
                    {/* Time Cursor */}
                    <Box
                      component="div"
                      sx={{
                        display: 'flex',
                        alignItems: 'stretch',
                        position: 'absolute',

                        height: '100%',
                        paddingTop: '1.5em',
                        marginTop: '-1.5em',
                      }}
                    >
                      <Box
                        component="div"
                        sx={{
                          position: 'absolute',
                          left: `${((hoursViewWidth / timelineHours) * (currentTime - startTime.valueOf())) / 60 / 60 / 1000}px`,
                          overflow: 'visible',
                          width: '4px',
                          height: '100%',
                          backgroundColor: '#000000',
                          border: '1px solid white',
                          boxSizing: 'border-box',
                        }}
                      >
                        <Box
                          component="div"
                          sx={{
                            width: '12px',
                            height: '12px',
                            borderRadius: '6px',
                            backgroundColor: '#000000',
                            border: '1px solid white',
                            position: 'absolute',
                            top: '0px',
                            left: `-5px`,
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  component="div"
                  ref={printersRef}
                  id="timeline-printers"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    justifyContent: 'flex-start',
                    gap: '0.5em',
                    width: `${PRINTER_PANEL_WIDTH}px`,
                    flexShrink: '0',
                    boxSizing: 'border-box',
                    height: 'max-content',
                    minHeight: '100%',
                    paddingTop: `${PRINTER_PANEL_PADDING}px`,
                    marginTop: '1.5em',
                    overflow: 'visible',
                  }}
                >
                  <TimelinePrinters tab={tab} />
                </Box>
              </Box>
            ) : tab === 'attention' ? (
              <EmptyState
                image={'/images/no-attention-required.svg'}
                title={t('devices:message.allGood')}
                message={t('devices:message.noDevicesNeedAttention')}
              />
            ) : (
              <EmptyState
                image={'/images/no-devices.svg'}
                title={t('devices:message.devicesPageInfo')}
                message={t('devices:message.addDevices')}
                button={
                  <Button
                    color="primary"
                    href={enrolDeviceLink}
                    target="_blank"
                    sx={{
                      mt: '1.5em',
                    }}
                  >
                    {t('button.add')}
                  </Button>
                }
              />
            )}
          </Box>
          <RightDrawer
            stateName={TIMELINE_DRAWER_STATE_NAME}
            width={mdDown ? '100%' : '40vw'}
            shift
            sx={{
              [theme.breakpoints.up('md')]: {
                maxWidth: '460px',
              },
              flexGrow: '1',
              flexShrink: '0',
              display: 'flex',
            }}
            padding="0px"
          >
            <DeviceViewer
              detailsStateName={TIMELINE_DRAWER_STATE_NAME}
              tableStateName={TIMELINE_TABLE_STATE_NAME}
            />
          </RightDrawer>
        </Box>
      ) : featureFlagTimeout || allowDevicesUi === false ? (
        <UnderConstruction />
      ) : (
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <CircularProgress size={24} />
        </Box>
      )}
    </Dashboard>
  )
}

export { Timeline }
export const TIMELINE_DRAWER_STATE_NAME = 'timelineDetailsState'
export const TIMELINE_TABLE_STATE_NAME = 'timelineTableState'
